import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Abbadon</h1>
        <h3>Delve into the afterlife</h3>
        <h2>Coming to Steam 2024</h2>
      </header>
    </div>
  );
}

export default App;
